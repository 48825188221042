.main-content-height {
  height: calc(100vh - 160px);
}

.table-as-index-height {
  max-height: calc(100vh - 281px);
}

.directory-as-index-height {
  height: calc(100vh - 205px);
}

.modal-body-max-height {
  max-height: calc(100vh - 120px);
}

.modal-content-max-height {
  max-height: calc(100vh - 140px);
}

.card-as-main-content-wrapper-height {
  height: calc(100vh - 190px);
}

.card-as-main-content-body-height {
  height: calc(100vh - 210px);
}
